<template lang="html">
	<div class="clearfix h-screen">
		<div class="flex h-full w-full overflow-hidden">
			FORGOT Password
		</div>
	</div>
</template>

<script>
  export default {
    name: 'ForgotPassword',
    components: {},
    data() {
      return {
        btnActive: false,
        userData: {}
      }
    },
    watch: {
      'userData': {
        handler(value) {
          const data = {...value}
          this.btnActive = !!(data.email)
        },
        deep: true
      }
    },
    methods: {
      async handleEmailForm(event) {
        event.preventDefault();
        const data = {...this.userData}

        if (this.btnActive) {
          const email = data.email;

          if (email.length <= 0) {
            this.actions.setErrors([{message: 'Please enter a email.'}])
            return;
          }

          await this.actions.triggerPasswordReset({email});

          this.$router.push({name: 'Login', params: {}, query: {}});

          this.actions.setErrors([{isSuccess: true, message: 'Please check your email'}])

        } else {
          console.log("Enter details")
        }
      }
    }
  }
</script>

<style lang="css" scoped>
</style>
